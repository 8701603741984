import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { ARLauncherPage } from './arlauncher.page';
import { SafePipe } from '../../shared/pipes/safe.pipe';

const routes: Routes = [
    {
        path: '',
        component: ARLauncherPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
    ],
    exports: [
        ARLauncherPage,
        SafePipe
    ],
    entryComponents: [
        ARLauncherPage
    ],
    declarations: [ARLauncherPage, SafePipe]
})
export class ARLauncherPageModule {
}
