import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ARDataModel } from '../../shared/models/ARData.model';

@Component({
  selector: 'app-arlauncher',
  templateUrl: './arlauncher.page.html',
  styleUrls: ['./arlauncher.page.scss'],
})
export class ARLauncherPage implements OnInit {

  // "value" passed in componentProps
  @Input() value: ARDataModel;

  public iframePrefix = '/assets/aframe-ar.html';

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
    localStorage.setItem('currentUser', JSON.stringify(this.value));
  }

  close() {
    this.modalCtrl.dismiss();
  }

}
